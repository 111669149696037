import { useEffect } from "react";

const useIdleTimeout = (logout, isAuthenticated, timeoutMinutes = 5) => {
  const timeoutMs = timeoutMinutes * 60 * 1000;
  let timeoutId = null;

  const resetTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      localStorage.setItem("logout", Date.now()); // Broadcast logout event
      logout(); // Perform actual logout
    }, timeoutMs);
  };

  const handleUserActivity = () => {
    if (isAuthenticated) {
      resetTimer();
      localStorage.setItem("lastActive", Date.now()); // Update last activity timestamp
    }
  };

  useEffect(() => {
    // Reset timer on user activity
    // window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    resetTimer(); // Start timeout initially

    // Listen for logout event from other tabs
    const handleStorageChange = (event) => {
      // this will capture logout event and logout to all tabs
      if (event.key === "logout") {
        logout();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      clearTimeout(timeoutId);
      // window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line
  }, [logout]);

  return null;
};

export default useIdleTimeout;
