import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui import
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";

// project import
import { tokens } from "../../theme";
import { existRegulationWithNoCitation } from "../../utilities/helpers";
import assignmentApi from "../../apis/assignmentApi";
import Item from "./item";
import General from "./general";
import Pai from "./pai";
import Practice from "./SideBar/practice";
import EqipSideBar from "../../components/EqipSideBar";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";
import { COMMONDITY_LIST } from "../../utilities/constants";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const [assignment, setAssignment] = useState(null);
  const [commodities, setCommodities] = useState([]);

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const inspectionUid = useSelector((state) => {
    return state.inspectionUid;
  });

  const assignmentUid = useSelector((state) => {
    return state.assignmentUid;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const lookup = useSelector((state) => {
    return state.lookup;
  });


  let subPart = ""; // hold subpart indicator to show its sections
  let subPartCode = "";
  // Q7
  let subPartQ7 = ""; // hold subpart indicator to show its sections
  let subPartCodeQ7 = "";

  // Cfr111
  let subPartCfr111 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr111 = "";
  
  // Cfr820
  let subPartCfr820 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr820 = "";

  // Cfr820211
  let subPartCfr820211 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr820211 = "";

  // Cfr211820
  let subPartCfr211820 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr211820 = "";

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const [responseAssignment, responseTenant
          ] = await Promise.all([
          assignmentApi.get(`/assignments/${assignmentUid}`), 
          ieqipCoreServiceApi.get(`/api/tenants/${tenantId}`)
        ]);

        setAssignment(responseAssignment.data.body[0]);
        setCommodities(responseTenant.data?.commodities?.split(',') || []);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();
  }, [inspectionUid, assignmentUid, idToken, tenantId]);

  const filterRegulations = (subSectionParentUid) => {
    const filteredSubSections = lookup.subSections.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = lookup.regulations.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguages = (citationLanguageParentUid) => {
    return lookup.citationLanguages.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Q7
  const filterRegulationsQ7 = (subSectionParentUid) => {
    const filteredSubSections = lookup.subSectionsQ7.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = lookup.regulationsQ7.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });
 
    return filteredRegulations;
  };

  const filterCitationLanguagesQ7 = (citationLanguageParentUid) => {
    return lookup.citationLanguagesQ7.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr111
  const filterRegulationsCfr111 = (subSectionParentUid) => {
    const filteredSubSections = lookup.subSectionsCfr111.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = lookup.regulationsCfr111.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr111 = (citationLanguageParentUid) => {
    return lookup.citationLanguagesCfr111.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr820
  const filterRegulationsCfr820 = (subSectionParentUid) => {
    const filteredSubSections = lookup.subSectionsCfr820.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = lookup.regulationsCfr820.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr820 = (citationLanguageParentUid) => {
    return lookup.citationLanguagesCfr820.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr820211
  const filterRegulationsCfr820211 = (subSectionParentUid) => {
    const filteredSubSections = lookup.subSectionsCfr820211.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = lookup.regulationsCfr820211.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr820211 = (citationLanguageParentUid) => {
    return lookup?.citationLanguagesCfr820211.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

    // Begin Cfr211820
    const filterRegulationsCfr211820 = (subSectionParentUid) => {
      const filteredSubSections = lookup?.subSectionsCfr211820.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);
  
      const filteredRegulations = filteredSubSections?.map((filteredSubSectionItem) => {
        const foundRegulation = lookup?.regulationsCfr211820.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
        return {
          lookup_uid: filteredSubSectionItem.lookup_uid,
          code: filteredSubSectionItem.code,
          description: filteredSubSectionItem.description,
          parent_uid: filteredSubSectionItem.parent_uid,
          description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
          regulation_code: foundRegulation?.code,
          regulation_lookup_uid: foundRegulation?.lookup_uid,
        };
      });
  
      return filteredRegulations;
    };
  
    const filterCitationLanguagesCfr211820 = (citationLanguageParentUid) => {
      return lookup.citationLanguagesCfr211820.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
    };
    // End Cfr211820

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.grey[1000]} !important`,
          borderRight: "1px solid",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.blueAccent[200]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: `${colors.blueAccent[100]} !important`,
        },
      }}
    >
      <EqipSideBar>
        {isLoading ? (
          <CircularProgress
            style={{
              margin: "10px 0 20px 100px",
            }}
          />
        ) : (
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              // onClick={() => setIsCollapsed(!isCollapsed)}
              // icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
                <Box display="flex" justifyContent="space-between">
                  {/* <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton> */}
                  <Typography variant="h3" color={colors.grey[100]} style={{ marginTop: "0.5em" }}>
                    {assignment?.subject}
                  </Typography>
                </Box>
            </MenuItem>

            {commodities.includes(COMMONDITY_LIST.DRUG) && <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" sx={{ m: "0 0 0 10px" }}>
                  DRUGS
                </Typography>
              </Box> */}
              <SubMenu
                key="drugs"
                title="DRUGS"
                style={{
                  color: colors.grey[100],
                }}
              >
              {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <SubMenu
                    key="paiDrug"
                    title="PAI - Finished Drug"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <Pai paiData={lookup?.paiDrugs} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <SubMenu
                    key="paiAPI"
                    title="PAI - API"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <Pai paiData={lookup?.paiApis} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <Practice lookupcodetype="CFR211" title="CFR 211" sections={lookup?.sections} subPart={subPart} subPartCode={subPartCode} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulations} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguages} />
                  <SubMenu
                    key="CFR212"
                    title="CFR 212"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                  <Practice lookupcodetype="Q7API" title="Q7 - API" sections={lookup?.sectionsQ7} subPart={subPartQ7} subPartCode={subPartCodeQ7} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsQ7} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesQ7} />
                  <Practice lookupcodetype="CFR211820" title="CFR 211820" sections={lookup?.sectionsCfr211820} subPart={subPartCfr211820} subPartCode={subPartCodeCfr211820} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr211820} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr211820} />
                </SubMenu>
                {/* Europe */}
                <SubMenu
                  key="eufda"
                  title="EU"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* KOREA */}
                <SubMenu
                  key="krfda"
                  title="KOREA MFD"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* CANADA */}
                <SubMenu
                  key="krfda"
                  title="Health Canada"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>}
            {commodities.includes(COMMONDITY_LIST.MEDICAL) && <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  MEDICAL DEVICES
                </Typography>
              </Box> */}
              <SubMenu
                key="medicalDevices"
                title="MEDICAL DEVICES"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />   
                  </SubMenu>
                  <Practice lookupcodetype="CFR820" title="CFR 820" sections={lookup.sectionsCfr820} subPart={subPartCfr820} subPartCode={subPartCodeCfr820} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr820} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr820} />
                  <Practice lookupcodetype="CFR820211" title="CFR 820211" sections={lookup.sectionsCfr820211} subPart={subPartCfr820211} subPartCode={subPartCodeCfr820211} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr820211} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr820211} />
                </SubMenu>
                {/* Europe */}
                <SubMenu
                  key="eufda"
                  title="EU"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* KOREA */}
                <SubMenu
                  key="krfda"
                  title="KOREA MFD"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* CANADA */}
                <SubMenu
                  key="krfda"
                  title="Health Canada"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>}
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  FOOD
                </Typography>
              </Box> */}
              {commodities.includes(COMMONDITY_LIST.FNB) && <SubMenu
                key="food"
                title="FOOD"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <Practice lookupcodetype="CFR111" title="CFR 111" sections={lookup.sectionsCfr111} subPart={subPartCfr111} subPartCode={subPartCodeCfr111} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr111} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr111} />
                </SubMenu>
              </SubMenu>}
              {commodities.includes(COMMONDITY_LIST.BIO) && <SubMenu
                key="biologis"
                title="BIOLOGICS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>}
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  FOOD
                </Typography>
              </Box> */}
              {commodities.includes(COMMONDITY_LIST.COMPROD) && <SubMenu
                key="comprod"
                title="COMBO PRODUCTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                  {/* <Practice lookupcodetype="CFR111" title="CFR 111" sections={sectionsCfr111} subPart={subPartCfr111} subPartCode={subPartCodeCfr111} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr111} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr111} /> */}
                </SubMenu>
              </SubMenu> }
              {commodities.includes(COMMONDITY_LIST.DISSUP) && <SubMenu
                key="DISSUP"
                title="DIETARY SUPPLEMENTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>}
              {commodities.includes(COMMONDITY_LIST.COS) && <SubMenu
                key="cos"
                title="COSMETICS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>}
              {commodities.includes(COMMONDITY_LIST.BIMO) && <SubMenu
                key="bimo"
                title="BIORESEARCH MONITORING PROGRAM"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={lookup?.generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>}
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  REPORTS
                </Typography>
              </Box> */}
              <SubMenu
                key="reports"
                title="REPORTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                <Item title="Inspection" to={`/inspection/${inspectionUid}/inspectionreport`} selected={selected} setSelected={setSelected} />
                <Item title="Observation" to={`/inspection/${inspectionUid}/observationordering`} selected={selected} setSelected={setSelected} />
                {/* <Item title="Observation Old" to={`/inspection/${inspectionUid}/citationreport`} selected={selected} setSelected={setSelected} /> */}
                {/* <Item title="Discussion Old" to={`/inspection/${inspectionUid}/discussionreport`} selected={selected} setSelected={setSelected} /> */}
                <Item title="Discussion" to={`/inspection/${inspectionUid}/discussionordering`} selected={selected} setSelected={setSelected} />
              </SubMenu>
            </Box>
          </Menu>
        )}
      </EqipSideBar>
    </Box>
  );
};

export default Sidebar;
