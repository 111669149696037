import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ieqipCoreServiceApi from "../apis/ieqipCoreServiceApi";

export const useTenants = () => {
  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const idToken = useSelector((state) => {
  //   return state.token;
  // });

  const accessToken = useSelector((state) => {
    return state.accessToken;
  });

  const fetchTenants = async () => {
    setIsLoading(true);
    try {
      const requestHeader = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
      };
      const response = await ieqipCoreServiceApi.get(`/api/tenants`, requestHeader);
      if (response && response.data) setTenants(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line  
  }, []);

  const deleteTenant = async (tenant) => {
    try {
      setIsLoading(true);
      await ieqipCoreServiceApi.delete(`/api/tenants/${tenant.tenant_uid}`);
      setTenants(tenants.filter((item) => item.tenant_uid !== tenant.tenant_uid));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return { tenants, deleteTenant, isLoading };
};
