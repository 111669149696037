import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import EqipRichTextDisplay from "../../../components/EqipRichTextDisplay";

const DetailReport = ({commentTitle, commentValue}) => {
    const GridWrapper = styled(Grid) ({
        border: "1px solid grey"
    });

    const Paper = styled(Grid) ({
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        fontFamily: "inherit",
        margin: 0
    });

    return <>{commentValue && 
        <GridWrapper item xs={12} style={{paddingLeft:'2em'}}>
            <Paper>{commentTitle}</Paper>
            <EqipRichTextDisplay>
                            <div
                              style={{
                                lineHeight: "1.5",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: commentValue || "",
                              }}
                            />
                          </EqipRichTextDisplay>
        </GridWrapper>
    }
    </>
}

export default DetailReport;