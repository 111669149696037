import axios from 'axios';
import { setLogout } from '../state';

// Create an axios instance
const ieqipCoreServiceApi = axios.create({
    baseURL: process.env.REACT_APP_IEQIP_CORE_SERVICE_API_URL
});

// Create a function to setup the interceptors that takes dispatch as a parameter
export const setupInterceptors = (dispatch) => {
    ieqipCoreServiceApi.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response?.status === 401) {
                // Now we can safely use dispatch
                dispatch(setLogout());
            }
            return Promise.reject(error);
        }
    );
};

export default ieqipCoreServiceApi;
