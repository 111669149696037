
import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID } from "../../../utilities/constants";
import { transformCitationLanguage } from "../../../utilities/helpers";
import DetailReport from "./DetailReport";

const DiscussionReport = ({inspection}) => {
    const GridWrapper = styled(Grid) ({
        border: "1px solid grey"
    });
    
    const PaperHeader = styled(Typography) ({
        fontSize: '1.5em',
    });

    const protocolQualities = inspection?.protocol_quality;
    const systemChoicesDiscussion = inspection?.system_choice_discussion;
    const filteredQuestionCitations = [];
    systemChoicesDiscussion?.forEach(systemChoiceDiscussion => {
        protocolQualities?.forEach(protocolQuality => {
            if (protocolQuality.detail?.coverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID && systemChoiceDiscussion.code_table_uid === protocolQuality.detail?.systemChoice) {
                filteredQuestionCitations.push(protocolQuality);
            }
        });
    })
    
    return ( filteredQuestionCitations &&
        <GridWrapper container item xs={12}>
            {filteredQuestionCitations.map((filteredQuestionCitation) => (
                <Grid key={filteredQuestionCitation?.code} container item xs={12}>
                    <GridWrapper item xs={12}>
                        <PaperHeader>
                            {transformCitationLanguage( filteredQuestionCitation?.detail?.citationLanguage)}
                        </PaperHeader>
                    </GridWrapper>
                    <DetailReport commentTitle="Specifically" commentValue={filteredQuestionCitation?.detail?.citationSpecifically} />
                    <DetailReport commentTitle="Supporting Evidence and Relevance" commentValue={filteredQuestionCitation?.detail?.citationSupporting} />
                    <DetailReport commentTitle="Recommendation" commentValue={filteredQuestionCitation?.detail?.citationDiscussion} />
                    <DetailReport commentTitle="Corrective Action Preventive Action (CAPA)" commentValue={filteredQuestionCitation?.detail?.citationCapa} />
                </Grid>
            ))}
        </GridWrapper> 
    );
};

export default DiscussionReport;
