import { Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import SystemChoiceDraggable from "./SystemChoiceDraggable";

const SystemChoiceDroppable = ({ systemChoices, questionCitations, coverageDndType, coverageChoice }) => {
  return (
    <Box>
      <Droppable droppableId="systemChoicesDroppableId" type="systemChoice">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {systemChoices?.map((systemChoice, index) => {
              return <SystemChoiceDraggable key={systemChoice?.code_table_uid} 
                systemChoice={systemChoice} 
                questionCitations={questionCitations} 
                coverageDndType={coverageDndType}
                coverageChoice={coverageChoice}
                index={index} />;
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default SystemChoiceDroppable;
