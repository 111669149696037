import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const EqipRichTextDisplay = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  margin: 0,
  
  "& table": {
    borderCollapse: "collapse",
    width: "100%",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  "& th, & td": {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "8px",
    textAlign: "left",
  },
  "& th": {
    backgroundColor: theme.palette.mode === "dark" 
      ? "rgba(255, 255, 255, 0.05)" 
      : "rgba(0, 0, 0, 0.02)",
  }
}));

export default EqipRichTextDisplay;