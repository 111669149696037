import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import ReactToPrint from "react-to-print";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { DragDropContext } from "react-beautiful-dnd";
import inspectionApi from "../../../apis/inspectionApi";
import SystemChoiceDroppable from "./SystemChoiceDroppable";
import ObservationReportGenerator from "../ObservationReportGenerator";
import CoverageDroppable from "./CoverageDroppable";
import { citationsHasNoSytemChoice } from "../../../utilities/helpers";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const CoverageDnD = ({ coverageDndType, coverageChoiceGuid, systemChoiceField }) => {
  const RootStyle = styled("div")({
    flexGrow: 1,
  });
  const reportComponentRef = useRef();
  return (
    <RootStyle>
      <ReactToPrint
        trigger={() => (
          <Button variant="contained" color="primary" style={{ float: 'right', marginRight: '10px' }} startIcon={<PictureAsPdfIcon />}>
            Print to PDF
          </Button>
        )}
        content={() => reportComponentRef.current}
      />
      <CoverageDnDPrintable ref={reportComponentRef} coverageDndType={coverageDndType} coverageChoiceGuid={coverageChoiceGuid} systemChoiceField={systemChoiceField} />
    </RootStyle>
  );
};

const CoverageDnDPrintable = React.forwardRef(({ coverageDndType, coverageChoiceGuid, systemChoiceField }, ref) => {
  const [inspection, setInspection] = useState(null);
  const [systemChoices, setSystemChoices] = useState([]);
  const [questionCitations, setQuestionCitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReportPreview, setShowReportPreview] = useState(false);

  const { inspectionUid } = useParams();

  const RootStyle = styled("div")({
    flexGrow: 1,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [responseInspection] = await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`)]);

      setInspection(responseInspection.data.body[0]);
      setSystemChoices(responseInspection.data.body[0][systemChoiceField]);
      setQuestionCitations(responseInspection?.data.body[0]?.protocol_quality);

      setLoading(false);
    })();
  }, [inspectionUid, systemChoiceField]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) return; // drop to outside droppable context
    if (destination.droppableId === source.droppableId && destination.index === source.index) return; // user drop back to start position

    if (type?.toLowerCase() === "systemChoice".toLowerCase()) {
      // Update draggable destination
      const newSystemChoices = Array.from(systemChoices);
      newSystemChoices.splice(source.index, 1); // remove source from array
      const newSystemChoice = systemChoices.find((systemChoice) => systemChoice?.code_table_uid === draggableId);
      newSystemChoices.splice(destination.index, 0, newSystemChoice); // insert destination to array

      // Update state
      setSystemChoices(newSystemChoices);

      // Save new systemChoice of inspection to database
      inspection[systemChoiceField] = newSystemChoices;
    } else if (type?.toLowerCase() === "discussion" || type?.toLowerCase() === "observation") {
      // Find the dragging questionCitation
      const foundQuestionCitation = questionCitations.find((questionCitation) => questionCitation?.code === draggableId);

      // Filter all questionCitations of found questionCitation's system choice
      const questionCitationsBySystemChoice = questionCitations.filter((questionCitation) => questionCitation?.detail.systemChoice === foundQuestionCitation.detail.systemChoice && questionCitation?.detail.coverageChoice === coverageChoiceGuid);

      // Perform swap
      const newQuestionCitations = Array.from(questionCitationsBySystemChoice);
      newQuestionCitations.splice(source.index, 1); // remove source from array
      const newQuestionCitation = questionCitations.find((questionCitation) => questionCitation?.code === draggableId);
      newQuestionCitations.splice(destination.index, 0, newQuestionCitation); // insert destination to array

      // Replace existing questionCitations's system choice with the new ones
      const questionCitationsExcludedSwapOnes = questionCitations.filter((questionCitation) => newQuestionCitations.filter((item) => item.code === questionCitation.code).length <= 0);
      const finalQuestionCitations = [...questionCitationsExcludedSwapOnes, ...newQuestionCitations];
      setQuestionCitations(finalQuestionCitations);

      // Save new systemChoice of inspection to database
      inspection.protocol_quality = finalQuestionCitations;
    }
    await inspectionApi.put(`/inspections/${inspection.inspection_uid}`, inspection);
  };

  const foundCitationHasNoSystemChoice = citationsHasNoSytemChoice(questionCitations);

  return (
    <RootStyle ref={ref}>
      <style type="text/css" media="print">
        {
          "\
          @media all {\
          .page-break {\
            display: none;\
          }\
        }\
        \
        @media print {\
          html, body {\
            height: auto !important;\
            overflow: visible !important;\
            -webkit-print-color-adjust: exact;\
          }\
        }\
        \
        @media print {\
          .page-break {\
            margin-top: 1rem;\
            display: block;\
            page-break-before: always;\
          }\
        }\
        \
        @page {\
          size: auto;\
          margin-left: 10mm;\
          margin-right: 10mm;\
          margin-top: 20mm;\
          margin-bottom: 20mm;\
        }\
      "
        }
      </style>
      <DragDropContext onDragEnd={onDragEnd}>
        {!showReportPreview && (
          <Box m="10px">
            <Button variant="contained" color="primary" startIcon={<PrintIcon />} onClick={() => setShowReportPreview(true)}>
              Print {coverageDndType}
            </Button>
          </Box>
        )}

        {!showReportPreview && (
          <Box m="10px">
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {!foundCitationHasNoSystemChoice && <SystemChoiceDroppable systemChoices={systemChoices} questionCitations={questionCitations} coverageDndType={coverageDndType} coverageChoice={coverageChoiceGuid} />}
            {foundCitationHasNoSystemChoice && <CoverageDroppable questionCitationsPerSystemChoice={questionCitations?.filter((p) => p.detail?.coverageChoice === coverageChoiceGuid)} coverageDndType={coverageDndType} coverageChoice={coverageChoiceGuid} />}
          </Box>
        )}
        {showReportPreview && (
          <Box>
            <ObservationReportGenerator reportType={coverageDndType} setShowReportPreview={setShowReportPreview} />
          </Box>
        )}
      </DragDropContext>
    </RootStyle>
  );
});

export default CoverageDnD;
