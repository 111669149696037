import { Grid, Paper, useTheme } from "@mui/material";
import styled from "@emotion/styled";

import { tokens } from "../../../theme";
import { getLookupByCode, getLookupDescriptionByCode } from "../../../utilities/helpers";
import EqipRichTextDisplay from "../../../components/EqipRichTextDisplay";

const GeneralReport = ({ inspection, lookupsByGeneral, lookups }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const GroupHeader = styled(Grid)(({ theme }) => ({
    padding: "12px 16px",
    margin: "16px 0",
    backgroundColor: "#f8f9fa",
    borderLeft: `4px solid ${colors.blueAccent[100]}`,
    borderRadius: "0 4px 4px 0",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    fontWeight: 600,
    fontSize: "1.1em",
    position: "relative",
    transition: "all 0.2s ease",
  }));

  const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.blueAccent[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  // filter quality_protocol by code
  const foundProtocolQualities = inspection?.protocol_quality?.filter((protocol_quality) => {
    return protocol_quality?.code?.startsWith("SM");
  });

  const foundGeneralGroups = lookupsByGeneral?.filter((lookupByGeneral) => lookupByGeneral.parent_uid === null);

  return (
    foundGeneralGroups &&
    foundProtocolQualities && (
      <>
        {foundGeneralGroups.map((group) => {
          const foundProtocolQualitiesByGroup = foundProtocolQualities?.filter((foundProtocolQuality) => getLookupByCode(lookups, foundProtocolQuality.code)?.parent_uid === group.lookup_uid);
          return (
            <Grid container key={group.lookup_uid}>
              <Grid container item spacing={1}>
                {foundProtocolQualitiesByGroup.length > 0 && (
                  <GroupHeader item xs={12}>
                    {group.description}
                  </GroupHeader>
                )}
                {foundProtocolQualitiesByGroup.map((foundProtocolQuality) => {
                  return (
                    <Grid container key={foundProtocolQuality.code}>
                      <Grid container item spacing={1}>
                        <Grid item xs={12}>
                          <ItemHeader>{getLookupDescriptionByCode(lookups, foundProtocolQuality?.code)}</ItemHeader>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "0.5em", marginLeft: "0.5em", marginBottom: "0.5em" }}>
                          <EqipRichTextDisplay>
                            <div
                              style={{
                                lineHeight: "1.5",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: foundProtocolQuality?.detail?.coverageComment || "",
                              }}
                            />
                          </EqipRichTextDisplay>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </>
    )
  );
};

export default GeneralReport;
