// components/shared/EqipRichTextEditor.jsx
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const EqipRichTextEditor = ({ value, onChange, placeholder }) => {
  const handleEditorChange = (content, editor) => {
    onChange(content);
  };

  return (
    <div className="eqip-rich-text-editor">
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        value={value || ''}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | link image table | removeformat | help',
          paste_data_images: true,
          image_title: true,
          automatic_uploads: true,
          placeholder: placeholder || 'Start typing...',
        }}
        onEditorChange={handleEditorChange}
      />
      <style jsx>{`
        .eqip-rich-text-editor {
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 4px;
        }
      `}</style>
    </div>
  );
};

export default EqipRichTextEditor;
