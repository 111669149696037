import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, AccordionActions, Button } from "@mui/material";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import NotesIcon from '@mui/icons-material/Notes';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import ModalDialog from "./ModalDialog";
// import UploadFile from "./UploadFile";
// import NoteForm from "../note/NoteForm";

// Customize Accordin will be use across application
const EqipAccordin = ({ children, title, showDragIndicator, allowUpload = false, allowNote = false, defaultExpanded = false, inspection, uid, code }) => {
    const RootStyle = styled("div") ({
        width: "100%"
    });

    const HeadingTypographyStyle = styled(Typography) ({
        // fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    })
    // const [openNoteModal, setOpenNoteModal] = useState(false);
    // const [openFileModal, setOpenFileModal] = useState(false);

    // const FileForm = () => {
    //     return <Grid container spacing={1}>
    //         <Grid item xs={12}>
    //             <UploadFile uploadFileParams={{ uid, inspection, code }} />
    //         </Grid>
    //     </Grid>
    // }

    // const onHandleNoteModalCancel = () => {
    //     setOpenNoteModal(false);
    // }

    // const onHandleNoteIconClick = (event) => {
    //     setOpenNoteModal(true);
    //     event.stopPropagation()
    // }

    // const onHandleFileModalOk = () => {
    //     setOpenFileModal(false);
    // }

    // const onHandleFileModalCancel = () => {
    //     setOpenFileModal(false);
    // }

    // const onHandleFileIconClick = (event) => {
    //     setOpenFileModal(true);
    //     event.stopPropagation()
    // }

    return <RootStyle>
        <Accordion style={{ marginBottom: '0.5em' }} defaultExpanded={defaultExpanded}>
            <AccordionSummary style={{ backgroundColor: 'rgba(0, 0, 0, .03)' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container>
                    <Grid item xs={12}>
                        {showDragIndicator && <DragIndicatorIcon />}
                        <HeadingTypographyStyle>{title}</HeadingTypographyStyle>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionActions>
                {allowUpload &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        // onClick={onHandleFileIconClick}
                    >
                        Upload
                    </Button>
                }
                {allowNote && <Button
                    variant="contained"
                    color="primary"
                    startIcon={<NotesIcon />}
                    // onClick={onHandleNoteIconClick}
                >
                    Reference
                </Button>
                }
            </AccordionActions>

            <AccordionDetails>
                {children}
            </AccordionDetails>

        </Accordion>
        {/* <ModalDialog
            title="Files"
            open={openFileModal}
            hideCancel={true}
            content={<FileForm />}
            handleOk={onHandleFileModalOk}
            handleCancel={onHandleFileModalCancel}
        />
        <ModalDialog
            title="Reference"
            open={openNoteModal}
            hideCancel={true}
            hideOK={true}
            content={<NoteForm inspection={inspection} code={code} onClose={onHandleNoteModalCancel} />}
        /> */}
    </RootStyle>
}

export default EqipAccordin;