import axios from 'axios';
import { setLogout } from '../state';

// Create an axios instance
const assignmentApi = axios.create({
    baseURL: process.env.REACT_APP_ASSIGNMENT_API_URL
});

// Create a function to setup the interceptors that takes dispatch as a parameter
export const setupInterceptorsForAssignmentApi = (dispatch) => {
    assignmentApi.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log("assignmentApi.interceptors.error", error);
            if (error.response?.status === 401) {
                // Now we can safely use dispatch
                dispatch(setLogout());
            } else if (error.request) {
                // Request was made but no response received
                // This could be network error, server down, CORS, etc.
                console.log('Request was made but no response received:', error.request);
                // You might want to logout here as well if appropriate
                dispatch(setLogout());
            } else {
                // Something happened in setting up the request
                console.log('Error setting up request:', error.message);
            }
            return Promise.reject(error);
        }
    );
};

export default assignmentApi;