import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Grid, CircularProgress, Paper, InputBase, Box, Button, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";
import { convertMessageToHtml } from "../../utilities/helpers";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { MESSAGE_STYLES } from "../../utilities/constants";

const Attachment = ({ uploadFileParams }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { assignmentId } = uploadFileParams;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMesssage] = useState("");
  const [citatons, setCitations] = useState();
  const [showAIChat, setShowAIChat] = useState(false);

  const [attachments, setAttachments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await ieqipCoreServiceApi.get(`/api/assignmentdocuments/assignmentid/${assignmentId}`, {
          headers: {
            "x-eqip-tenantid": tenantId,
          },
        });
        setAttachments(res.data);
        setLoading(false);
        setSelectedRows(null)
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [assignmentId, tenantId]);

  const getDocuments = async () => {
    const res = await ieqipCoreServiceApi.get(`/api/assignmentdocuments/assignmentid/${assignmentId}`, {
      headers: {
        "x-eqip-tenantid": tenantId,
      },
    });
    return res.data;
  };

  const handleUploadFile = async (event) => {
    const foundFile = event.target.files[0];
    setLoading(true);
    try {
      //1. Get upload presigned url
      const formData = new FormData();
      formData.append("file", foundFile);
      formData.append("bucket_name", "eqip-document-dev");
      const responseUploadedFile = await ieqipCoreServiceApi.post("/api/s3/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      //2. Insert record to document table in database
      await ieqipCoreServiceApi.post(`/api/assignmentdocuments`, {
        assignment_document_uid: uuidv4(),
        tenant_id: tenantId,
        assignment_id: assignmentId,
        original_filename: responseUploadedFile.data.original_filename,
        uploaded_filename: responseUploadedFile.data.uploaded_filename,
        etag: responseUploadedFile.data.response.ETag,
        created_date: new Date().toISOString(),
        created_by: loggedInUser.email,
      });

      //3. Reload document list
      setAttachments(await getDocuments());
    } catch (error) {
      console.error("Uploaded failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async (attachment) => {
    setLoading(true);
    try {
      const response = await ieqipCoreServiceApi.get(`/api/s3/download/${attachment.uploaded_filename}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachment.original_filename || attachment.uploaded_filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = async (attachment) => {
    setLoading(true);
    try {
      //1. remove file from S3
      await ieqipCoreServiceApi.delete(`/api/s3/delete/${attachment.uploaded_filename}`);

      //2. delete document from database
      await ieqipCoreServiceApi.delete(`/api/assignmentdocuments/${attachment.assignment_document_uid}`, {
        headers: {
          "x-eqip-tenantid": tenantId,
        },
      });

      setAttachments(await getDocuments());
    } catch (error) {
      console.error("Delete failed", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleAI = async () => {
    setLoading(true);
    setErrorMessage(null);
    setTitle(null);
    setMesssage(null);
    setCitations(null);
    try {
      if (!selectedRows || selectedRows?.length === 0) {
        setErrorMessage("Please select at least one file to process.");
        return;
      }
      // Map the selected files and create an array of promises
      const filePromises = selectedRows.map(async (attachmentId) => {
        const attachment = attachments.find((attachment) => attachment.id === attachmentId);

        if (!attachment) {
          console.warn(`Attachment with ID ${attachmentId} not found`);
          return null;
        }

        try {
          const response = await ieqipCoreServiceApi.get(`/api/s3/download/${attachment.uploaded_filename}`, { responseType: "blob" });

          return new File([response.data], attachment.uploaded_filename, { type: response.data.type });
        } catch (error) {
          console.error(`Failed to download file ${attachment.uploaded_filename}:`, error);
          return null;
        }
      });

      // Wait for all file downloads to complete
      const downloadedFiles = await Promise.all(filePromises);

      // Filter out any null values from failed downloads
      const validFiles = downloadedFiles.filter((file) => file !== null);

      if (validFiles.length === 0) {
        throw new Error("No valid files to process");
      }

      // Create FormData and append files
      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Upload files to OpenAI assistant
      await ieqipCoreServiceApi.post("/api/openai/assistant/uploadfile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setShowAIChat(true);
    } catch (error) {
      console.error("AI processing failed:", error);
      // You might want to add user notification here
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setShowAIChat(false);
    setSelectedRows(null);
  };

  const onHandleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setMesssage(null);
      setCitations(null);
      const response = await ieqipCoreServiceApi.post(`/api/openai/assistant/ask`, {
        question: title,
      });
      setMesssage(response.data.message);
      setCitations(response.data.citations);
    } catch (error) {
      console.error("Delete failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const columns = [
    {
      field: "original_filename",
      headerName: "File Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "assignment_uid",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton color="secondary" aria-label="delete file" component="span" onClick={() => handleDeleteFile(row)}>
              <DeleteForeverIcon />
            </IconButton>
            <IconButton color="secondary" aria-label="download file" component="span" onClick={() => handleDownloadFile(row)}>
              <GetAppIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: "0.5em" }}>
        <input id="icon-button-file" type="file" onChange={handleUploadFile} />
      </Grid>
      <Box display="flex" justifyContent="end">
        {!showAIChat && (
          <Button disabled={loading} color="primary" variant="contained" startIcon={<AutoAwesomeIcon />} onClick={() => handleAI()}>
            Copilot
          </Button>
        )}
        {showAIChat && (
          <Button disabled={loading} color="primary" variant="contained" startIcon={<ArrowBackIcon />} onClick={() => handleBack()}>
            Back
          </Button>
        )}
      </Box>
      {errorMessage && (
        <Box sx={{ marginTop: 1 }}>
          <Alert onClose={() => setErrorMessage("")} severity="error">{errorMessage}</Alert>
        </Box>
      )}
      {!showAIChat && (
        <Box
          m="10px 0 0 0"
          height="50vh"
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.blueAccent[600],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[900],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.grey[900],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[500]} !important`,
            },
          }}
        >
          <DataGrid checkboxSelection={true} rows={attachments} columns={columns} loading={loading} onSelectionModelChange={(selectionModel) => handleSelectionChange(selectionModel)} />
        </Box>
      )}
      {showAIChat && (
        <Box>
          <Box sx={{ marginTop: 1 }}>
            <Paper component="form" elevation={3} sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
              <InputBase sx={{ ml: 1, flex: 1 }} color="info" placeholder="Send a message..." inputProps={{ "aria-label": "Send a message..." }} value={title} onChange={(e) => setTitle(e.target.value)} />
              <IconButton type="submit" color="primary" sx={{ p: "10px" }} aria-label="search" onClick={onHandleSend}>
                {loading ? <CircularProgress size={23} /> : <SendIcon color="primary" />}
              </IconButton>
            </Paper>
          </Box>
          {message && <Box sx={{ marginTop: 1 }}>
            <Paper>
              <div
                dangerouslySetInnerHTML={{
                  __html: convertMessageToHtml(message),
                }}
                style={{
                  padding: "16px",
                  lineHeight: "1.5",
                  "& pre": MESSAGE_STYLES.pre,
                  "& code": MESSAGE_STYLES.code,
                  "& strong": MESSAGE_STYLES.strong,
                  "& em": MESSAGE_STYLES.em,
                }}
              />
            </Paper>
          </Box>}
          {citatons && <Box sx={{ marginTop: 1 }}>
            <Paper>
              <div
                dangerouslySetInnerHTML={{
                  __html: convertMessageToHtml(citatons),
                }}
                style={{
                  padding: "16px",
                  lineHeight: "1.5",
                  "& pre": MESSAGE_STYLES.pre,
                  "& code": MESSAGE_STYLES.code,
                  "& strong": MESSAGE_STYLES.strong,
                  "& em": MESSAGE_STYLES.em,
                }}
              />
            </Paper>
          </Box>}
        </Box>
      )}
    </>
  );
};

export default Attachment;
