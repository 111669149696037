import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

// mui import
import { CssBaseline, ThemeProvider } from "@mui/material";

// third party
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";

// project import
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Tenants from "./scenes/tenants";
import TenantForm from "./scenes/tenants/form";
import Users from "./scenes/users";
import UserForm from "./scenes/users/form";
import Assignments from "./scenes/assignments";
import AssignmentForm from "./scenes/assignments/form";
import Firms from "./scenes/firms";
import FirmForm from "./scenes/firms/form";
import Lookups from "./scenes/lookups";
import Form from "./scenes/form";
import FAQ from "./scenes/faq";
import Login from "./scenes/Auth/Login";
import InspectionSidebar from "./scenes/inspection/Sidebar";
import InspectionForm from "./scenes/inspection/form";
import InspectionReport from "./scenes/reports/inspectionreport";
import ObservationReport from "./scenes/reports/ObservationReport";
// import ObservationOrdering from "./scenes/reports/ObservationOrdering";
// import DiscussionOrdering from "./scenes/reports/DiscussionOrdering";
import ObservationReportGenerator from "./scenes/reports/ObservationReportGenerator";
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, REPORT_TYPE } from "./utilities/constants";
import { setIsAuthenticated, setLogout } from "./state";
import CoverageDnD from "./scenes/reports/CoverageOrdering";
import AIv2 from "./scenes/aiv2";
import useIdleTimeout from "./hooks/useIdleTimeout";
import { setupInterceptors } from "./apis/ieqipCoreServiceApi";
import { setupInterceptorsForFirmApi } from "./apis/firmApi";
import { setupInterceptorsForAssignmentApi } from "./apis/assignmentApi";
import { setupInterceptorsForSecurityApi } from "./apis/securityApi";

function App() {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();

  const isShowInspection = Boolean(useSelector((state) => {
    return state.isShowInspection;
  })); 

  const isAuthenticated = Boolean(useSelector((state) => {
    return state.isAuthenticated;
  }));

  useEffect(() => {
    setupInterceptors(dispatch);
    setupInterceptorsForFirmApi(dispatch)
    setupInterceptorsForAssignmentApi(dispatch)
    setupInterceptorsForSecurityApi(dispatch)
  }, [isAuthenticated, dispatch]);

  const [isSidebar, setIsSidebar] = useState(true);

  const handleLogout = () => {
    //console.log("User logged out due to inactivity", new Date());
    setIsAuthenticated(false);
    // Perform additional logout logic (e.g., clear auth tokens, redirect)
    dispatch(setLogout());
  };

  useIdleTimeout(handleLogout, isAuthenticated, 60); // 30 minutes timeout (change as needed)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}> {/* 1 second */}
          <CssBaseline />
          {
            isAuthenticated ? 
              <div className="app">
                <Sidebar isSidebar={isSidebar} />
                {isShowInspection && <InspectionSidebar />}
                <main className="content">
                  <Topbar setIsSidebar={setIsSidebar} />
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/tenants" element={<Tenants />} /> 
                    <Route path="/tenants/create" element={<TenantForm />} />
                    <Route path="/tenants/:tenant_id/edit" element={<TenantForm />}></Route>
                    <Route path="/users" element={<Users />} /> 
                    <Route path="/users/create" element={<UserForm />} />
                    <Route path="/firms" element={<Firms />} />
                    <Route path="/firms/create" element={<FirmForm />} />
                    <Route path="/firms/:firm_id/edit" element={<FirmForm />}></Route>
                    <Route path="/lookup" element={<Lookups />}></Route>
                    <Route path="/assignments/create" element={<AssignmentForm />} />
                    <Route path="/assignments/:assignment_uid/edit" element={<AssignmentForm />}></Route>
                    <Route path="/assignments" element={<Assignments />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/inspection/:inspectionUid/citationLanguageCode/:citationLanguageCode" element={<InspectionForm />} />
                    <Route path="/inspection/:inspectionUid/subPartCode/:subPartCode/citationLanguageCode/:citationLanguageCode/lookupcodetype/:lookupcodetype" element={<InspectionForm />} />
                    <Route path="/inspection/:inspectionUid/inspectionreport" element={<InspectionReport />} />
                    <Route path="/inspection/:inspectionUid/citationreport" element={<ObservationReport reportType={REPORT_TYPE.OBSERVATION}/>} />
                    <Route path="/inspection/:inspectionUid/observationordering" element={<CoverageDnD 
                      coverageDndType={REPORT_TYPE.OBSERVATION}
                      coverageChoiceGuid={COVERAGE_CHOICE_CITATION_GUID}
                      systemChoiceField="system_choice_observation" />} />
                    <Route path="/inspection/:inspectionUid/printcitation" element={<ObservationReportGenerator reportType={REPORT_TYPE.OBSERVATION} />}/>
                    <Route path="/inspection/:inspectionUid/discussionreport" element={<ObservationReport reportType={REPORT_TYPE.DISCUSSION}/>} />
                    <Route path="/inspection/:inspectionUid/discussionordering" element={<CoverageDnD 
                      coverageDndType={REPORT_TYPE.DISCUSSION}
                      coverageChoiceGuid={COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID}
                      systemChoiceField="system_choice_discussion" />} />
                    <Route path="/inspection/:inspectionUid/printdiscussion" element={<ObservationReportGenerator reportType={REPORT_TYPE.DISCUSSION}/>}/>
                    {/* {routeList?.length > 0 && routeList.map(route => {
                      switch (route.Code) {
                        case 'ROUTE_FIRM':
                          return <Route path="/firms" element={<Firms />} />;
                        case 'ROUTE_ASSIGNMENT':
                          return <Route path="/assignments" element={<Assignments />} />
                        case 'ROUTE_LOOKUP':
                            return <Route path="/lookup" element={<Lookups />}></Route>;
                        case 'ROUTE_USER':
                          return <Route path="/users" element={<Users />} />;
                        default:
                          return <Route key={route.Code} exact path="/"><Dashboard /></Route>;
                    }})} */}
                    <Route path="/aidocument" element={<AIv2 />} />
                  </Routes>
                </main>
              </div>
              :
              <Login /> 
          }
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
