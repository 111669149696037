import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Stack,
  Alert,
} from "@mui/material";
import { red, yellow, green, blue } from '@mui/material/colors';
import SaveIcon from "@mui/icons-material/Save";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { existRegulationWithNoCitation, getCitationLanguageWithoutPlaceHolder, isFoundSystemChoicesBySubPartCode } from "../../utilities/helpers";
import CitationStatementChip from "./CitationStatementChip";
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, SNACKBAT_AUTO_HIDE_DURATION } from "../../utilities/constants";
import lookupApi from "../../apis/lookupApi";
import inspectionApi from "../../apis/inspectionApi";
// import { CloudUpload, Help, Note } from "@mui/icons-material";
import { CloudUpload} from "@mui/icons-material";
import Attachment from "./attachment";
import ModalDialog from "../../components/ModalDialog";
import NoteForm from "./note";
import SimpleAccordin from "../../components/SimpleAccordin";
import GptForm from "./gpt";
import assignmentApi from "../../apis/assignmentApi";
import EqipRichTextEditor from "../../components/EqipRichTextEditor";

// Short citation form
const Form = () => {
  const loggedInUser = useSelector((state) => {
    return state.user;
  });
  const [errorMessage, setErrorMessage] = useState('');

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inspection, setInspection] = useState(null);
  const [selectedSystemChoice, setSelectedSystemChoice] = useState("1");
  const [selectedCoverageChoice, setSelectedCoverageChoice] = useState("1");
  const [selectedCitationLanguage, setSelectedCitationLanguage] = useState(null);
  const [selectedShortCitationLanguage, setSelectedShortCitationLanguage] = useState(null)
  const [initialValues, setInitialValues] = useState({
    coverageComment: null,
    supporting: null,
    specifically: null,
    discussion: null,
    capa: null,
  });
  const { inspectionUid, subPartCode, citationLanguageCode, lookupcodetype } = useParams();
  const [openEqipGPTModal, setOpenEqipGPTModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [responseInspection, responseShortCitationLanguage] = await Promise.all([
          inspectionApi.get(`/inspections/${inspectionUid}`),
          lookupcodetype ? 
            lookupApi.get(`/lookups/lookupcodes/${citationLanguageCode}/lookupcodetypes/${lookupcodetype}`) :
            lookupApi.get(`/lookups/lookupcodes/${citationLanguageCode}`)
        ]);
        const foundInspection = responseInspection.data.body[0];

        setInspection(foundInspection);

        cleanUp();

        // Set citation language
        const foundShortCitationLanguage = responseShortCitationLanguage.data.body[0];
        let foundLongCitationLanguage;
        if (foundShortCitationLanguage) {
          setSelectedShortCitationLanguage(foundShortCitationLanguage);
          foundLongCitationLanguage = await lookupApi.get(`/lookups/parent/${foundShortCitationLanguage?.lookup_uid}`);
          setSelectedCitationLanguage(foundLongCitationLanguage?.data?.body[0]?.description);
        }

        
        // fetch protocol quality
        if (
          foundInspection?.protocol_quality &&
          Array.isArray(foundInspection?.protocol_quality)
        ) {
          const foundProtocolQuality =
            foundInspection?.protocol_quality?.filter(
              (protocolQualityItem) =>
                protocolQualityItem.code === citationLanguageCode && protocolQualityItem.lookupcodetype === lookupcodetype
            )[0];
          setSelectedSystemChoice(foundProtocolQuality?.detail?.systemChoice);
          setSelectedCoverageChoice(
            foundProtocolQuality?.detail?.coverageChoice
          );
          setSelectedRating(
            foundProtocolQuality?.detail?.rating
          );
          setInitialValues({
            coverageComment: foundProtocolQuality?.detail?.coverageComment,
            supporting: foundProtocolQuality?.detail?.citationSupporting,
            specifically: foundProtocolQuality?.detail?.citationSpecifically,
            discussion: foundProtocolQuality?.detail?.citationDiscussion,
            capa: foundProtocolQuality?.detail?.citationCapa,
          });

          // Set default citation language from database if any
          setSelectedCitationLanguage(foundProtocolQuality?.detail?.citationLanguage ?? foundLongCitationLanguage?.data.body[0]?.description);
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();
  }, [inspectionUid, citationLanguageCode, lookupcodetype]);

  const handleSystemChoiceChange = (event) => {
    setSelectedSystemChoice(event.target.value);
  };

  const handleCoverageChange = (event) => {
    setSelectedCoverageChoice(event.target.value);
  };

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  const handleFormSubmit = async (values) => {
    try {
      console.log("values", values);
      setIsLoading(true);
      const responseAssignmentSignatures = await assignmentApi.get(
        `/assignments/9/assignmentsignatures/inspectionuid/${inspectionUid}/assigntoemail/${loggedInUser.email}`
      );
      if (responseAssignmentSignatures?.data?.body?.id) {
        setErrorMessage('You cannot save because you have signed the report. Please delete the signature in order to making any changes')
        setIsLoading(false);
        return;
      }
      const newOrUpdatedProtocolQuality = [
        {
          code: citationLanguageCode,
          lookupcodetype: lookupcodetype,
          detail: {
            systemChoice: selectedSystemChoice,
            coverageChoice: selectedCoverageChoice,
            coverageComment: values.coverageComment,
            citationDiscussion: values.discussion,
            citationSupporting: values.supporting,
            citationSpecifically: values.specifically,
            citationLanguage: selectedCitationLanguage,
            citationCapa: values.capa,
            rating: selectedRating,
          },
        },
      ];

      // Ensure inspection.protocol_quality is initialized as an array
      if (!Array.isArray(inspection.protocol_quality)) {
        inspection.protocol_quality = [];
      }

      // Ask Chat GPT 
      // Message 1: merge 2 array object with 2 primary elements in javascript
      // Message 2: no, it isn't result I'm looking for. I want to merge only the different element in each array
      const mergedArray = [...inspection.protocol_quality.filter(item => item.code !== newOrUpdatedProtocolQuality[0].code), ...newOrUpdatedProtocolQuality];
      inspection.protocol_quality = mergedArray;

      await inspectionApi.put(`/inspections/${inspectionUid}`, inspection);

      enqueueSnackbar("The inspection save successfully!", {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to save", error);
      setErrorMessage(`Failed to save the inspection. Error: ${error}`)
      setIsLoading(false);
    }
  };

  // reset data
  const cleanUp = () => {
    setInitialValues({
      coverageComment: null,
      supporting: null,
      specifically: null,
      discussion: null,
      capa: null,
    });
  };

  // Begin EqipGPT modal
  const onHandleEqipGPTModalCancel = () => {
    setOpenEqipGPTModal(false);
  }

  // Begin Upload File modal
  const onHandleFileModalOk = () => {
    setOpenFileModal(false);
  }

  const onHandleFileModalCancel = () => {
    setOpenFileModal(false);
  }

  const onHandleFileIconClick = (event) => {
    setOpenFileModal(true);
    event.stopPropagation()
  }
  // End Upload File modal

  // Begin Note modal
  const onHandleNoteModalCancel = () => {
    setOpenNoteModal(false);
  }

  return (
    <Box m="20px">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorMessage.length > 0 && 
      <Alert onClose={() => setErrorMessage('')} 
                severity="error"
                sx={{ marginBottom: '0.5em' }}
              >
                {errorMessage}
      </Alert>
      }
      <Formik
        // onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="10px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl sx={{ gridColumn: "span 4" }}>
                <SimpleAccordin title={selectedShortCitationLanguage?.description}>
                  {getCitationLanguageWithoutPlaceHolder(selectedCitationLanguage)}
                </SimpleAccordin>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUpload />}
                    onClick={onHandleFileIconClick}
                  >
                    Attach Files
                  </Button>
                </Box>  
              </FormControl>
              {!existRegulationWithNoCitation(citationLanguageCode) && ( // don't show system choice and coverage choice if there is notication
                <>
                  <FormControl sx={{ gridColumn: "span 4" }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                      System Choice
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="system-choice-radio-buttons-group-label"
                      name="system-choice-radio-buttons-group"
                      row
                      value={selectedSystemChoice ?? null}
                      onChange={handleSystemChoiceChange}
                    >
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Quality') && <FormControlLabel
                        value="35fad871-5a30-4937-b4c3-e66b070f9cf1"
                        control={<Radio />}
                        label="Quality"
                      />}
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Facilities/Equipment') && <FormControlLabel
                        value="e6f02680-948f-4b1b-9138-b4bc608f126d"
                        control={<Radio />}
                        label="Facilities/Equipment"
                      />}
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Materials') && <FormControlLabel
                        value="a5dd5bef-4def-4d7d-9113-0dee6c9876f4"
                        control={<Radio />}
                        label="Materials"
                      />}
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Production') && <FormControlLabel
                        value="bcb11489-0473-4199-90db-1d1ea7d36250"
                        control={<Radio />}
                        label="Production"
                      />}
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Packaging/Labeling') && <FormControlLabel
                        value="d18f6840-0d95-4188-894f-a5f784b3fa52"
                        control={<Radio />}
                        label="Packaging/Labeling"
                      />}
                      {isFoundSystemChoicesBySubPartCode(subPartCode, 'Laboratory') && <FormControlLabel
                        value="92d55e3d-6901-4396-8a87-ba9d6b3ea0d4"
                        control={<Radio />}
                        label="Laboratory"
                      />}
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4" }}>
                    <FormLabel id="coverage-radio-buttons-group-label">
                      Coverage
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="coverage-radio-buttons-group-label"
                      name="coverage-radio-buttons-group"
                      row
                      value={selectedCoverageChoice ?? null}
                      onChange={handleCoverageChange}
                    >
                      <FormControlLabel
                        value="0d0511a1-d98f-45ec-aef4-9f6c94cbd102"
                        control={<Radio />}
                        label="Observation/Major Finding"
                      />
                      <FormControlLabel
                        value="a499a5e7-0d55-4d91-867d-cbe852f9e2e4"
                        control={<Radio />}
                        label="Discussion/Minor Finding"
                      />
                      <FormControlLabel
                        value="ba553ae7-3c14-4d10-a317-1ae563c17e8e"
                        control={<Radio />}
                        label="Covered/No Finding"
                      />
                      <FormControlLabel
                        value="27375d7f-5e28-44be-8833-0bc82b872ebd"
                        control={<Radio />}
                        label="Not Covered"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4" }}>
                    <FormLabel id="rating-radio-buttons-group-label">
                      Rating
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="rating-radio-buttons-group-label"
                      name="rating-radio-buttons-group"
                      row
                      value={selectedRating ?? null}
                      onChange={handleRatingChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio sx={{ color: red[900], '&.Mui-checked': { color: red[900], }}}/>}
                        label="1"
                        sx={{ color: red[900]}}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio sx={{ color: red[800], '&.Mui-checked': { color: red[800], }}}/>}
                        label="2"
                        sx={{ color: red[800]}}
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio sx={{ color: red[700], '&.Mui-checked': { color: red[700], }}}/>}
                        label="3"
                        sx={{ color: red[700]}}
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio sx={{ color: yellow[700], '&.Mui-checked': { color: yellow[700], }}}/>}
                        label="4"
                        sx={{ color: yellow[700]}}
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio sx={{ color: yellow[600], '&.Mui-checked': { color: yellow[600], }}}/>}
                        label="5"
                        sx={{ color: yellow[600]}}
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio sx={{ color: yellow[500], '&.Mui-checked': { color: yellow[500], }}}/>}
                        label="6"
                        sx={{ color: yellow[500]}}
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio sx={{ color: green[900], '&.Mui-checked': { color: green[900], }}}/>}
                        label="7"
                        sx={{ color: green[900]}}
                      />
                      <FormControlLabel
                        value="8"
                        control={<Radio sx={{ color: green[800], '&.Mui-checked': { color: green[800], }}}/>}
                        label="8"
                        sx={{ color: green[800]}}
                      />
                      <FormControlLabel
                        value="9"
                        control={<Radio sx={{ color: green[700], '&.Mui-checked': { color: green[700], }}}/>}
                        label="9"
                        sx={{ color: green[700]}}
                      />
                      <FormControlLabel
                        value="10"
                        control={<Radio sx={{ color: blue[600], '&.Mui-checked': { color: blue[600], }}}/>}
                        label="10"
                        sx={{ color: blue[600]}}
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {/* <TextField
                fullWidth
                variant="filled"
                multiline
                type="text"
                label="Comment for report body"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.coverageComment ?? ""}
                name="coverageComment"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 4" }}
              /> */}
              <FormControl sx={{ gridColumn: "span 4" }}>
                <EqipRichTextEditor fullWidth theme="snow"
                  value={values.coverageComment} 
                  onChange={(content) => setFieldValue('coverageComment', content)} // Use setFieldValue instead 
                  onBlur={handleBlur}
                  placeholder="Comment for report body"/>
              </FormControl>
              {(selectedCoverageChoice === COVERAGE_CHOICE_CITATION_GUID || selectedCoverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID) && (
                <>
                  <FormControl sx={{ gridColumn: "span 4", marginLeft: "10px" }}>
                    <CitationStatementChip 
                      selectedCitationLanguage={selectedCitationLanguage}
                      setSelectedCitationLanguage={setSelectedCitationLanguage}  />
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4", marginLeft: "10px" }}>
                    <EqipRichTextEditor fullWidth theme="snow" 
                      value={values.specifically} 
                      onChange={(content) => setFieldValue('specifically', content)} // Use setFieldValue instead 
                      onBlur={handleBlur}
                      placeholder="Specifically"/>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4", marginLeft: "10px" }}>
                    <EqipRichTextEditor fullWidth theme="snow" 
                      value={values.supporting} 
                      onChange={(content) => setFieldValue('supporting', content)} // Use setFieldValue instead 
                      onBlur={handleBlur}
                      placeholder="Supporting evidence and relevance"/>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4", marginLeft: "10px" }}>
                    <EqipRichTextEditor fullWidth theme="snow" 
                      value={values.discussion} 
                      onChange={(content) => setFieldValue('discussion', content)} // Use setFieldValue instead 
                      onBlur={handleBlur}
                      placeholder="Recommendation"/>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 4", marginLeft: "10px" }}>
                    <EqipRichTextEditor fullWidth theme="snow" 
                      value={values.capa} 
                      onChange={(content) => setFieldValue('capa', content)} // Use setFieldValue instead 
                      onBlur={handleBlur}
                      placeholder="Corrective Action Preventive Action (CAPA)"/>
                  </FormControl>
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => handleFormSubmit(values)} //use this if formik submit doesn't trigger
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Box>
            <Stack spacing={2} direction="row">
              <ModalDialog
                title="Files"
                open={openFileModal}
                hideCancel={true}
                content={<Attachment uploadFileParams={{ 
                  inspectionUid, 
                  code: citationLanguageCode }} />}
                handleOk={onHandleFileModalOk}
                handleCancel={onHandleFileModalCancel}
              />
              <ModalDialog
                title="Reference"
                open={openNoteModal}
                hideCancel={true}
                hideOK={true}
                content={<NoteForm inspection_uid={inspectionUid} code={citationLanguageCode} onClose={onHandleNoteModalCancel} />}
              />
              <ModalDialog
                title="Ask ieQip"
                open={openEqipGPTModal}
                hideCancel={true}
                content={<GptForm />}
                handleOk={onHandleEqipGPTModalCancel}
              />
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  comment: yup.string(),
  supporting: yup.string(),
  specifically: yup.string(),
  discussion: yup.string(),
  capa: yup.string(),
});

export default Form;
