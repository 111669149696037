import { useState } from "react";
import { useParams } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { Box, Button, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { transformCitationLanguage } from "../../../utilities/helpers";
import SimpleAccordin from "../../../components/SimpleAccordin";
import inspectionApi from "../../../apis/inspectionApi";
import _ from "lodash";
import EqipRichTextEditor from "../../../components/EqipRichTextEditor";

const CoverageDraggable = ({ questionCitation, index, type }) => {
  const { citationLanguage, citationSpecifically } = questionCitation.detail;
  const [value, setValue] = useState(citationSpecifically);
  const [isLoading, setIsLoading] = useState(false);
  const transformedCitationLanguage = transformCitationLanguage(citationLanguage);
  const { inspectionUid } = useParams();

  const updateInspection = async () => {
    setIsLoading(true);
    try {
      const [responseInspection] = await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`)]);
      const inspection = responseInspection.data.body[0];
      const foundQuestion = inspection?.protocol_quality.find(question => question.code === questionCitation.code);
      foundQuestion.detail.citationSpecifically = value;
      await inspectionApi.put(`/inspections/${inspection.inspection_uid}`, inspection);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Draggable draggableId={questionCitation.code} index={index}>
      {(provided) => (
        <Box m="2px" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <SimpleAccordin title={`${_.camelCase(type)} ${index + 1}. ${transformedCitationLanguage}`} isDragNDrop>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {transformedCitationLanguage}
              </Grid>
              <Grid item xs={12}>
                <EqipRichTextEditor fullWidth theme="snow" 
                      value={value} 
                      onChange={setValue}
                      placeholder="Specifically"/>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" color="primary" variant="contained" onClick={() => updateInspection()} disabled={isLoading} startIcon={<SaveIcon />}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </SimpleAccordin>
        </Box>
      )}
    </Draggable>
  );
};

export default CoverageDraggable;
