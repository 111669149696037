export const ANSWER_CHOICE = "ANSWER_CHOICE";
export const ANSWER_COMMENT = "ANSWER_COMMENT";
export const ANSWER_FILE = "ANSWER_FILE";

export const COVERAGE_CHOICE_CITATION_GUID = '0d0511a1-d98f-45ec-aef4-9f6c94cbd102';
export const COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID = 'a499a5e7-0d55-4d91-867d-cbe852f9e2e4';

export const SHORT_CITATION_CODE = "SHORT_CITATION_CODE";
export const SYSTEM_CHOICE = "SYSTEM_CHOICE";
export const COVERAGE_CHOICE = "COVERAGE_CHOICE";
export const COVERAGE_COMMENT = "COVERAGE_COMMENT";

export const CITATION_LANGUAGE = "CITATION_LANGUAGE";
export const CITATION_SPECIFICALLY = "CITATION_SPECIFICALLY";
export const CITATION_SUPPORTING = "CITATION_SUPPORTING";
export const CITATION_DISCUSSION = "CITATION_DISCUSSION";

export const REGULATION_CODE = "REGULATION_CODE";

export const CITATION_LANGUAGE_DELIMETER = '<#>';

export const NO_DATA_FOUND = 'No data found';

export const ROLE_USER = '39e93687-438e-4ff5-a04c-d8cfed9e7195';
export const ROLE_BIOLOGIST = 'e6faecd9-8890-4f50-a2de-4a3ddb5668df';
export const ROLE_CHEMIST = '347d7cea-01f1-43f1-8844-01e7e06ef14b';
export const ROLE_ADMIN = '59c1cdcf-b4b3-43e3-a2e0-90b9d5693409';
export const EQIP_ROLE_ADMIN = '1cc34d3e-c3d6-4666-9940-5eaf69c85f1e';
export const ROLE_SUPERVISOR = 'f01a2354-6097-4612-8384-deca5947b829';
export const ROLE_LEAD_USER = 'bd8ce1af-3f1a-4eb1-8fde-9df0c71fca8c';

export const SNACKBAT_AUTO_HIDE_DURATION = 1000; // 1 second

export const CONVERT_SQUARE_BRACKET_TO_ARRAY = /(?:\[)[^\][]*(?=])/g;

export const LOOKUP_CATEGORIES = {
  GENERAL: 'fa403199-3199-4a29-a03c-02657fa0d3f8',
  CFR: 'e26ad1d5-8ce1-455b-8541-c2df1652cd8b',
  PAI_DRUG: '7a427afa-c400-47f9-a67b-96475bc70af2',
  PAI_API: '360f7766-3ad1-42d7-a9c3-a3ff10197692',
}

export const LOOKUP_CFR_TYPES = {
  COMMONDITY: 'fa2381ab-2a61-4fe5-a029-31361ea00a0a',
  SUB_COMMONDITY: 'b2edda67-e207-408a-9ac4-ffc945e17e4f',
  SUB_PART: 'ac0884c5-6df7-419d-be52-ce67e74e7807',
  SECTION: 'cfb5b88a-aa61-4b9f-a5db-0ddea9902a39',
  SUB_SECTION: '094edfe3-4f10-4856-9a4c-a9a64fb47595',
  REGULATION: 'e13bcf66-3518-4615-baea-a624a88eaec1',
  SHORT_CITATION_LANGUAGE: 'd63efa58-2f5c-4bbf-b214-fc2bfd41189e',
  LONG_CITATION_LANGUAGE: 'c06684cc-6fd3-4465-a285-f92d18fced2b'
}

export const TENANT_MASTER = '81f8c1db-13ce-4138-b5f4-28f855e5202a';

export const REPORT_TYPE = {
  OBSERVATION: 'Observation',
  DISCUSSION: 'Discussion',
}

export const ARTIFICIAL_INTELLIGENCE = {
  MAX_FILE_UPLOAD_TO_PIPECONE: 5
}

export const MFA_TYPES = { //Multi-factore authentication types
  MFA_SETUP: 'MFA_SETUP', // Come from aws cognito
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA', // Come from aws cognito
  TOKEN_MFA_VALIDATION: 'TOKEN_MFA_VALIDATION' // Self defined
}

export const MARKET_INDENTED_LIST = ['FDA483', 'ISPE', 'PDA', 'ADVERTISEMENTMARKETING',
                    'USP', 'USFDATRAINING', 'USFDAIMPORT', 'USFDAAPPROVALS',
                    'EUTRAINING', 'KOREAMFDTRAINING',
                    'CANADAHEALTHTRAINING', 'CHINANMPATRAINING',
                    'INDIACDSCOTRAINING', 'WARNINGUNTITLEDLETTER', 'RECALL', 'INDUSTRY']

export const COMMONDITY_LIST = { DRUG: 'DRUG', MEDICAL: 'MEDICAL',  FNB: 'FNB', BIO: 'BIO', COMPROD: 'COMPROD', DISSUP: 'DISSUP', COS: 'COS', BIMO: 'BIMO'}

export const FIRM_TYPE = {
  AUDITING_FIRM: 75,
  FIRM_TO_BE_AUDITED: 76,
}

export const AUDIT_OPRATIONS = [
  {label: 'D', value: 'Delete'},
  {label: 'I', value: 'Insert'},
  {label: 'U', value: 'Update'},
]

export const INSPECTION_CODE_TYPE_HAS_NO_SYSTEMCHOICE = [
  'Q7API'
]

export const SIGNATURE_REPORT_TYPES = {
  INSPECTION: 84,
  OBSERVATION: 85,
  DISCUSSION: 86,
}

export const MESSAGE_STYLES = {
  pre: {
    backgroundColor: "#f5f5f5",
    overflowX: "auto",
  },
  code: {
    fontFamily: "monospace",
  },
  strong: {
    fontWeight: "bold",
  },
  em: {
    fontStyle: "italic",
  },
};

