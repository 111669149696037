import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import assignmentApi from "../../apis/assignmentApi";
import firmApi, { setupInterceptorsForFirmApi } from "../../apis/firmApi";
import { FIRM_TYPE } from "../../utilities/constants";
import securityApi from "../../apis/securityApi";
import { LOOKUP_CATEGORIES, LOOKUP_CFR_TYPES } from "../../utilities/constants";
import lookupApi from "../../apis/lookupApi";
import { setLookup } from "../../state";

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [auditingFirms, setAuditingFirms] = useState([]);
  const [auditedFirms, setAuditedFirms] = useState([]);
  const [users, setUsers] = useState([]);
  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const lookup = useSelector((state) => {
    return state.lookup;
  });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };

        const [responseAssignments, responseUsers] =
        await Promise.all([
          assignmentApi.get(`/assignments/assignto/${loggedInUser.email}`,requestHeader),
          securityApi.get(`/users`, requestHeader)
        ]);

        const responseFirms = await firmApi.get(`/firms`, requestHeader);

        setAssignments(responseAssignments.data.body);
        setAuditingFirms(responseFirms.data.body.filter(firm => firm.firm_type_id === FIRM_TYPE.AUDITING_FIRM));
        setAuditedFirms(responseFirms.data.body.filter(firm => firm.firm_type_id === FIRM_TYPE.FIRM_TO_BE_AUDITED));
        setUsers(responseUsers.data.body);

        if (!lookup) {
          const [responseGeneral, responsePaiDrug, responsePaiApi, 
            responseSection, responseSubsection, responseRegulation, responseCitationLanguage, 
            // Q7
            responseSectionQ7, responseSubsectionQ7, responseRegulationQ7, responseCitationLanguageQ7,
            // Cfr111
            responseSectionCfr111, responseSubsectionCfr111, responseRegulationCfr111, responseCitationLanguageCfr111,
            // Cfr820
            responseSectionCfr820, responseSubsectionCfr820, responseRegulationCfr820, responseCitationLanguageCfr820,
            // Cfr820211
            responseSectionCfr820211, responseSubsectionCfr820211, responseRegulationCfr820211, responseCitationLanguageCfr820211,
            // Cfr211820
            responseSectionCfr211820, responseSubsectionCfr211820, responseRegulationCfr211820, responseCitationLanguageCfr211820] 
            = await Promise.all([
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.GENERAL}`, requestHeader), // get all generals
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_DRUG}`, requestHeader), // get all pai drug
            lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_API}`, requestHeader), // get all pai api
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/Q7API`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/Q7API`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR111`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR111`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820211`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820211`, requestHeader), // get all citation languages
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sub sections
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211820`, requestHeader), // get all regulations
            lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211820`, requestHeader), // get all citation languages
          ]);
    
          dispatch(
            setLookup({ lookup: {
              generals: responseGeneral.data.body,
              paiDrugs: responsePaiDrug.data.body,
              paiApis: responsePaiApi.data.body,
              // cfr211
              sections: responseSection.data.body,
              subSections: responseSubsection.data.body,
              regulations: responseRegulation.data.body,
              citationLanguages: responseCitationLanguage.data.body,
              // Q7
              sectionsQ7: responseSectionQ7.data.body,
              subSectionsQ7: responseSubsectionQ7.data.body,
              regulationsQ7: responseRegulationQ7.data.body,
              citationLanguagesQ7: responseCitationLanguageQ7.data.body,
              // cfr111
              sectionsCfr111: responseSectionCfr111.data.body,
              subSectionsCfr111: responseSubsectionCfr111.data.body,
              regulationsCfr111: responseRegulationCfr111.data.body,
              citationLanguagesCfr111: responseCitationLanguageCfr111.data.body,
              // Cfr820
              sectionsCfr820: responseSectionCfr820.data.body,
              subSectionsCfr820: responseSubsectionCfr820.data.body,
              regulationsCfr820: responseRegulationCfr820.data.body,
              citationLanguagesCfr820: responseCitationLanguageCfr820.data.body,
              // Cfr820211
              sectionsCfr820211: responseSectionCfr820211.data.body,
              subSectionsCfr820211: responseSubsectionCfr820211.data.body,
              regulationsCfr820211: responseRegulationCfr820211.data.body,
              citationLanguagesCfr820211: responseCitationLanguageCfr820211.data.body,
      
              // Cfr211820
              sectionsCfr211820: responseSectionCfr211820.data.body,
              subSectionsCfr211820: responseSubsectionCfr211820.data.body,
              regulationsCfr211820: responseRegulationCfr211820.data.body,
              citationLanguagesCfr211820: responseCitationLanguageCfr211820.data.body
              }
            })
          );
        }
        setIsLoading(false);
        setupInterceptorsForFirmApi(dispatch);
      } catch (err) {
        // Handle Error Here
        console.error(err);
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [loggedInUser?.email, tenantId]);

  return (
    <Box m="20px">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* HEADER
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ieQip" subtitle="Welcome To Your ieQip Dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box> */}

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? <CircularProgress size="20px"/> : assignments.length}
            subtitle="Assignments"
            // progress="0.80"
            // increase="+99%"
            icon={
              <ReceiptOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? <CircularProgress size="20px"/> : auditedFirms.length}
            subtitle="Firms Audited"
            // progress="0.50"
            // increase="+99%"
            icon={
              <ContactsOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? <CircularProgress size="20px"/> : auditingFirms.length}
            subtitle="Auditing Firms"
            // progress="0.30"
            // increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={isLoading ? <CircularProgress size="20px"/> : users.length}
            subtitle="Users"
            // progress="0.75"
            // increase="+99%"
            icon={
              <PeopleOutlinedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>


        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Inspections
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.blueAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.blueAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box> */}

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.blueAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
