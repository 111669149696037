import { Grid, useTheme } from "@mui/material";
import styled from "@emotion/styled";

import CoverageReport from "./CoverageReport";
import { tokens } from "../../../theme";

const CFRReport = ({ inspection, codeTables, lookups }) => {
  const theme = useTheme();
  const SYSTEM_CHOICE_CODE_TABLE = 6;
  const systemChoiceCodeTables = codeTables?.filter(
    (codeTable) => codeTable.code_table_type_id === SYSTEM_CHOICE_CODE_TABLE
  );
  const colors = tokens(theme.palette.mode);

  const ItemHeader = styled(Grid)(({ theme }) => ({
    padding: '12px 16px',
    backgroundColor: '#f8f9fa',
    borderLeft: `4px solid ${colors.blueAccent[100]}`,
    borderRadius: '0 4px 4px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    fontWeight: 600,
    fontSize: '1.1em',
    position: 'relative',
    transition: 'all 0.2s ease',
  }));

  const showDidNotCover = (systemChoice) => {
    return inspection?.protocol_quality?.filter(
      (protocol_quality) =>
        protocol_quality?.detail?.systemChoice === systemChoice.code_table_uid
    ).length > 0 ? "" : " - Did Not Cover";
  }

  return systemChoiceCodeTables &&
    <Grid container spacing={2}>
      {systemChoiceCodeTables.map((systemChoice) => (
        <>
        <Grid key={systemChoice.id} item xs={12}>
            <ItemHeader>{systemChoice.description} {showDidNotCover(systemChoice)}</ItemHeader>
        </Grid>
        <Grid key={systemChoice.id} item xs={12}>
            <CoverageReport
              inspection={inspection}
              lookups={lookups}
              codeTables={codeTables}
              systemChoiceUid={systemChoice.code_table_uid}
            />
        </Grid>
        </>
      ))}
    </Grid>
};

export default CFRReport;
