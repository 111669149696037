import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// mui import
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import StorageIcon from '@mui/icons-material/Storage';

// project import
import { setInspection } from "../../state";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  // Modify the Item component's onClick to save to localStorage
  const handleMenuSelect = (title) => {
    setSelected(title);
    localStorage.setItem('selectedLeftNavMenuItem', title);
    dispatch(
      setInspection({
        isShowInspection: false
      })
    );
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => handleMenuSelect(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  // Initialize selected state from localStorage, fallback to "Dashboard"
  const [selected, setSelected] = useState(() => 
    localStorage.getItem('selectedLeftNavMenuItem') || "Dashboard"
  );
  
  const loggedInUser = useSelector((state) => {
    return state.user;
  }); 

  const userRole = useSelector((state) => {
    return state.role;
  }); 

  const routeList = useSelector((state) => {
    return state.routeList?.routeList;
  }); 

  const isShowInspection = Boolean(useSelector((state) => {
    return state.isShowInspection;
  })); 

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.grey[1000]} !important`,
          borderRight: "1px solid"
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.blueAccent[200]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: `${colors.blueAccent[100]} !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed || isShowInspection}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed && !isShowInspection)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && !isShowInspection && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ieQip
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed && !isShowInspection)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && !isShowInspection && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {loggedInUser?.email}
                </Typography>
                <Typography variant="h5" color={colors.grey[300]}>
                  {userRole}
                </Typography>
              </Box>
            </Box>
          )}

          <Box>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            {routeList?.length > 0 && routeList.map(route => {
              switch (route.code) {
                case 'ROUTE_FIRM':
                  return <Item
                  title="Firms"
                  to="/firms"
                  icon={<BusinessIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />;
                case 'ROUTE_LOOKUP':
                    return <Item
                    title="Lookups"
                    to="/lookup"
                    icon={<DriveFolderUploadIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />;
                case 'ROUTE_USER':
                  return <Item
                  title="Users"
                  to="/users"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />;
                case 'ROUTE_TENANT':
                  return <Item
                  title="Tenants"
                  to="/tenants"
                  icon={<StoreIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />;
                case 'ROUTE_AI_DOCS':
                  return <Item
                  title="Manage AI Document"
                  to="/aidocument"
                  icon={<StorageIcon/>}
                  selected={selected}
                  setSelected={setSelected}
                />;
                default: 
                  return <></>;
            }})}
            
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Process
            </Typography>
            <Item
              title="Assignments"
              to="/assignments"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
{/* 
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
