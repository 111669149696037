import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useUsers } from "../../hooks/useUsers";
import ModalDialog from "../../components/ModalDialog";
import { getLocalDateTime } from "../../utilities/helpers";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import RoleFormModal from "./RoleFormModal";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const { users, managers, roleList, isLoading, fetchUsers, deleteUser } = useUsers();
  const columns = [
    {
      field: "Username",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Role",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>{row.role?.description}</>
        );
      },
    },
    {
      field: "tenant_name",
      headerName: "Company/Tenant",
      flex: 1,
    },
    {
      field: "UserStatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "UserCreateDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>{getLocalDateTime(row.UserCreateDate)}</>
        );
      },
    },
    {
      field: "Enabled",
      headerName: "Enable",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Set Role">
              <IconButton color="warning" onClick={() => handleSetRole(row)} variant="" disabled={row?.tenant_name.includes('eQip') && row.Username?.includes('khiem')}>
                <SupervisedUserCircleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => handleDelete(row)} variant="" disabled={row?.tenant_name === 'ieQip' && row.Username?.includes('khiem')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    
  ];

  const handleDeleteOk = async () => {
    setOpenDeleteModal(false);
    await deleteUser(selectedUser);
  }

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = (user) => {
    setOpenDeleteModal(true);
    setSelectedUser(user)
  }

  const handleSetRole = async (user) => {
    setRoleModal(true);
    setSelectedUser(user)
  }

  const handleSetRoleOk = async () => {
    setRoleModal(false);
  }

  const handleSetRoleCancel = async () => {
    setRoleModal(false);
  }

  return (
    <Box m="20px">
      <Header title="USER" subtitle="Managing the Users" />
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/users/create"
        >
          Create User
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          loading={isLoading}
          getRowId={(row) => row.id}
        />
      </Box>
      <ModalDialog
        title="Delete"
        content="Are you sure you want to delete this item?"
        open={openDeleteModal}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel} />

      <ModalDialog
        title="Set Role"
        content={<RoleFormModal 
          roleList={roleList} 
          managers={managers} 
          selectedUser={selectedUser} 
          setRoleModal={setRoleModal} 
          fetchUsers={fetchUsers}/>}
        open={roleModal}
        handleOk={handleSetRoleOk}
        handleCancel={handleSetRoleCancel}
        hideOK={true}
        hideCancel={true}
      />
    </Box>
  );
};

export default Users;
